<template>
  <div class="flex justify-end bg-white rounded-md p-2">
    <div class="atras flex align-middle">
      <i class="pi pi-angle-left text-blue-900 my-auto cursor-pointer"
         style="font-size: 1.5rem"
         @click="$router.go(-1)"
      />
    </div>
    <div class="text-[#354357] font-bold my-auto" >
      <h2 class="text-lg">
        {{props.title}}
      </h2>
    </div>
    <div class="p-inputgroup">
    </div>
    <div v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" class="crear flex justify-end" style="min-width: 244px">
      <Button label="Ingresar nueva tutela" class="bg-blue-900" @click="goto()"/>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  name: 'filtros',
  props: {
    title: {
      type: String,
      default: 'Tutelas'
    }
  },
  setup (props) {
    const router = useRouter()
    const goto = () => {
      router.push({ name: 'pharmasan.administrativa.juridica.tutelas.formulario' })
    }
    return {
      props,
      goto
    }
  }
}
</script>
<style scoped></style>
